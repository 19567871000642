import UnknownPic from '../assets/UnknownPic.png';
import onePxTransparent from '../assets/1pxTransparent.png';
import LogoKC from '../assets/logo-kingconf.png';
import {
	__IMAGEHANDLER_URL__, __APIURLVIRTUALEXPO__, __API_TRIVIA_URL__, __APIURLVIRTUALEXPO_,
	__APIKCURL__, __API_ATTENDEES_URL__, __BR_ROUNDS_GROUP_ONE__, __BR_ROUNDS_GROUP_TWO__, __API_ROOMS_URL__,
	__API_LEADERBOARD_URL__, __API_REDEEM_POINTS_URL__, __APIKCURL_V3__,
	__API_LEADERBOARDBRIEF_URL__, __API_SPONSORS_URL__, __API_VIDEO_URL__, __APIPACKAGEURL__, __BR_ATTENDEES_PAGINATED__,
	__LIVEAPIURL__, __BR_MEETING__, __API_SPEAKERS_URL__, __API_SESSIONS_CONTENT_URL__, __API_VIRTUAL_BASIC_INFO__,
	__API_EXHIBITOR_REPRESENTATIVE__, __BR_MY_MEETINGS_SLOTS__, __BR_BLOCK_SLOT__, __BR_UNBLOCK_SLOT__, __BR_RESPONSE_MEETING__
} from '../consts/consts';
import Storage from './Storage';
import axios from 'axios';
import { store } from '../index';
import { dispatch } from 'redux';
import { configureStore } from '../redux/store'
import { updateCalendar } from '../redux/actions/businessRounds.actions'
import Utils from './Utils';
import { setGameResults, updateGameResults } from '../redux/actions';
import moment from 'moment';

let ApiCalls = {
	getTrivias: function (challengeId) {
		return new Promise(resolve => {

			const options = {
				url: __API_TRIVIA_URL__ + challengeId,
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json;charset=UTF-8'
				}

			};
			axios(options).then(response => {
				if (response.data && response.data.trivias) {
					var trivias = response.data.trivias.filter(o => o.deleted == 0);
					var idTrivias = Array.from(trivias, p => Number(p.id));

					var triviaAnswers = [];
					trivias.forEach(trivia => {
						triviaAnswers = triviaAnswers.concat(trivia.triviaItems);
					});
					resolve({ trivias, triviaAnswers });


				}
			})
				.catch(err => {
					Utils.sendErrorToServer("Ocurrió un error en getTrivias", "API CALLS", JSON.stringify(err));
				});
		});

	},
	sendGameResults: function (idTrivia, IdTriviaItemAnswered) {
		return new Promise(resolve => {

			const options = {
				url: __API_LEADERBOARD_URL__,
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json;charset=UTF-8'

				},
				data: { "IdEvent": Number(Storage.getIdEvent()), "IdRegistration": Number(Storage.getIdRegistration()), "IdTrivia": Number(idTrivia), "IdTriviaItemAnswered": Number(IdTriviaItemAnswered), "AnswerDateTime": new Date().toISOString() }

			};
			axios(options).then(response => {

				if (response.status == 201 || response.status == 200) {
					store.dispatch(updateGameResults(response.data.earnedPoints));
					resolve(response);
				}
			}).catch(err => {
				console.log(err);
				Utils.sendErrorToServer("Ocurrió un error en sendGameResults", "API CALLS", JSON.stringify(err));
			})
		});

	},

	logOnTriviaClick(challengeId, idExhibitor) {
		let formdata = new FormData();
		formdata.append("idExhibitor", idExhibitor);
		formdata.append("additionalData", 'idChallenge:' + challengeId);
		formdata.append("idEvent", Storage.getIdEvent());


		const options = {
			url: __APIURLVIRTUALEXPO__ + 'logger/logOnTriviaClick',
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json;charset=UTF-8',
				//La siguiente linea hace la magia de autenticar con el token!
				'Authorization': 'bearer ' + Storage.getJwtToken()
			},
			data: formdata
		};
		axios(options).then(response => {
			console.log(response);

		}).catch(error => {

			console.log(error);
			Utils.sendErrorToServer("Ocurrió un error en logOnTriviaClick", "API CALLS", JSON.stringify(error));

		})
	},
	redeemPoints: function () {
		return new Promise(resolve => {

			const options = {
				url: __API_REDEEM_POINTS_URL__ + Storage.getIdRegistration(),
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json;charset=UTF-8'

				},
				data: {

					registrationCode: 'ASD123',
				}

			};
			axios(options).then(response => {

				if (response.status == 200) {
					resolve({ promoCode: response.data });


				}
			})
				.catch(err => {
					Utils.sendErrorToServer("Ocurrió un error en redeemPoints", "API CALLS", JSON.stringify(err));
				});
		});

	},
	getLeaderboard: function () {
		return new Promise(resolve => {

			const options = {
				url: __API_LEADERBOARDBRIEF_URL__ + Storage.getIdRegistration(),
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json;charset=UTF-8'
				}

			};
			axios(options).then(response => {
				if (response.data) {

					store.dispatch(setGameResults(response.data.totalPoints));
					resolve(response.data);


				}
			})
				.catch(err => {
					Utils.sendErrorToServer("Ocurrió un error en getLeaderboard", "API CALLS", JSON.stringify(err));
				});
		});

	},

	logOnClickBanner(idExhibitor, additionalDataName) {
		return new Promise(resolve => {
			let formdata = new FormData();
			formdata.append("idExhibitor", idExhibitor);
			formdata.append("additionalData", "{name:" + additionalDataName + "}");

			const options = {
				url: __APIURLVIRTUALEXPO__ + 'logger/logOnBannerClick',
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json;charset=UTF-8',
					//La siguiente linea hace la magia de autenticar con el token!
					'Authorization': 'bearer ' + Storage.getJwtToken()
				},
				data: formdata
			};
			axios(options).then(response => {

				console.log(response);
				resolve();

			}).catch(error => {
				console.log(error);

			})
		});
	},

	getNews() {
		return new Promise(resolve => {
			const options = {
				url: __APIKCURL_V3__ + 'news',
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json;charset=UTF-8',
					'x-registrationCode': Storage.getPersonRegistrationCode()
				},
			};
			axios(options).then(response => {
				var news = response.data != null ? response.data.filter(o => o.deleted != 1).sort((a, b) => b.publishedDate.localeCompare(a.publishedDate)) : [];
				resolve(news);
			});
		}).catch(error => {
			console.log("Error fetching package news: ")
			console.log(error)
		});
	},
	/* Nacho agregando functions */
	getVideos() {
		return new Promise(resolve => {
			const options = {
				url: __API_VIDEO_URL__,
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json;charset=UTF-8'
				}

			};
			axios(options).then(response => {

				if (response.data) {

					var videosList = response.data.filter(o => o.deleted == 0);

					resolve(videosList);
				}
			});
		})
	},
	getSpeakers() {
		return new Promise(resolve => {
			const options = {
				url: __APIPACKAGEURL__,
				method: "GET",
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json;charset=UTF-8',
					'Access-Control-Allow-Credentials': 'true'
				},
			};

			axios(options).then(response => {

				resolve(response);
			})
		})
	},
	eventFinished() {
		return new Promise(resolve => {
			const options = {
				url: __API_VIRTUAL_BASIC_INFO__,
				method: "GET",
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json;charset=UTF-8',
					'Access-Control-Allow-Credentials': 'true'
				},
			};

			axios(options).then(response => {
				resolve(response);
			})
		})
	},
	getPosters() {
		return new Promise(resolve => {
			const options = {
				url: __APIPACKAGEURL__,
				method: 'GET',
				headers: {
					'Content-Type': 'application/json;charset=UTF-8'
				}

			}
			axios(options).then(resp => {
				resolve(resp.data);

			}).catch(error => {
				console.log("Error during Poster Fetch: ")
				console.log(error)

			})
		})
	},
	getConversationsBusinessRound() {
		return new Promise(resolve => {
			const options = {
				url: __BR_ATTENDEES_PAGINATED__ + "?currentPage=1&resultsPerPage=3000&searchString=&orderByFields=Person.Company&IsBusinessRound=true",
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json;charset=UTF-8',
					'registrationCode': Storage.getPersonRegistrationCode()
				},

			};
			axios(options).then(response => {
				let resp = response;
				resolve(resp);
			})
		})
	},
	getBusinessRoundType() {
		const endpointURL = __APIKCURL_V3__ + 'businessRoundConfiguration?utcTimeStamp=2012-01-01'
		return axios(endpointURL)
			.then(({ data }) => data)
	}
	,
	getRequestForVideoCall(formdata) {
		return new Promise(resolve => {
			const options = {
				url: __APIURLVIRTUALEXPO__ + 'notification/Requestvideocall',
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json;charset=UTF-8',
					'Access-Control-Allow-Credentials': 'true',
					//La siguiente linea hace la magia de autenticar con el token!
					'Authorization': 'bearer ' + Storage.getJwtToken()
				},
				data: formdata
			};
			axios(options).then(response => {
				resolve(response);
			}).catch(error => {
				console.log(error);
				console.log(error.response);
			})
		})
	},
	getWords() {
		return new Promise(resolve => {
			const options = {
				url: __LIVEAPIURL__ + 'wordscloud/' + Storage.getIdEvent() + '/',
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json;charset=UTF-8',
					'Access-Control-Allow-Credentials': 'true'
				},
			};

			axios(options).then(response => {
				resolve(response.data);
			}
			);
		})
	},
	getVideoMeeting(slotId) {
		return new Promise(resolve => {
			const options = {
				url: __BR_MEETING__ + slotId,
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json;charset=UTF-8'
				}

			};
			axios(options).then(results => {
				resolve(results);
			});
		})
	},
	getSponsorsData() {
		return new Promise(resolve => {
			const options = {

				url: __APIKCURL__ + 'sponsors/' + Storage.getIdEvent(),
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json;charset=UTF-8'
				},

			};
			console.log("actualizando datos a las " + new Date().toISOString())
			axios(options).then(response => {
				resolve(response);
			})
		})
	},
	getOnlySpeakers() {
		return new Promise(resolve => {
			const options = {
				url: __API_SPEAKERS_URL__,
				method: "GET",
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json;charset=UTF-8',
					'Access-Control-Allow-Credentials': 'true'
				}
			}
			axios(options).then(response => {
				resolve(response);
			}).catch(error => {
				console.log("Error")
				console.log(error)
			})
		})
	},
	/* Darle un vistazo */
	getActivityDescription(activity) {
		return new Promise(resolve => {
			const options = {
				url: __API_SESSIONS_CONTENT_URL__ + "/" + activity.id,
				method: "GET",
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json;charset=UTF-8'
				}
			}
			axios(options).then(response => {
				resolve(response);
			})
		})
	},
	optConf() {
		return new Promise(resolve => {
			const options = {
				url: __APIKCURL_V3__ + 'exhibitionStands/' + Storage.getMyStandToRepresent().id,
				method: "GET",
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json;charset=UTF-8'
				},
			}
			axios(options).then(res => {
				resolve(res.data);
			})
				.catch(err => {
					console.log("Error en GET trayendo datos de conferencia")
					console.log(err.data)
				})
		})
	},
	/* Up to here */
	logGenericAction(endpoint, additionalData) {
		return new Promise(resolve => {
			let formdata = new FormData();
			if (additionalData != null) {
				formdata.append("additionalData", additionalData);
			}

			const options = {
				url: __APIURLVIRTUALEXPO__ + 'logger/' + endpoint,
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json;charset=UTF-8',
					//La siguiente linea hace la magia de autenticar con el token!
					'Authorization': 'bearer ' + Storage.getJwtToken()
				},
				data: formdata
			};
			axios(options).then(response => {

				console.log(response);
				resolve();

			}).catch(error => {
				console.log(error);
			})
		});
	},

	logOnSponsorsClick() {
		return this.logGenericAction("logOnSponsorsClick");
	},
	logOnSponsorDetailClick(idSponsor) {
		return this.logGenericAction("logOnSponsorDetailClick", "{idSponsor:" + idSponsor + "}");
	},
	logOnLobbyClick() {
		return this.logGenericAction("logOnLobbyClick");
	},

	logOnNetworkingClick() {
		return this.logGenericAction("logOnNetworkingClick");
	},
	logOnLiveStreamClick(roomName) {
		return this.logGenericAction("logOnLiveStreamClick", "{name:" + roomName + "}");
	},
	logOnPostersClick() {
		return this.logGenericAction("logOnPostersClick");
	},
	logOnPosterDetailClick(idActivity) {
		return this.logGenericAction("logOnPosterDetailClick", "{idActivity:" + idActivity + "}");
	},
	logOnHtmlLinkMenuClick(menuName) {
		return this.logGenericAction("logOnHtmlLinkMenuClick", "{menuName:" + menuName + "}");
	},



	getRooms: function () {
		return new Promise(resolve => {
			axios.get(__API_ROOMS_URL__).then(resp => {

				resolve(resp.data);

			}).catch(error => {
				console.log("Error fetching package RoomStreaming: ")
				console.log(error)
			})
		});
	},
	getSponsors: function () {
		return new Promise(resolve => {
			const options = {

				url: __APIKCURL__ + 'sponsors/' + Storage.getIdEvent(),
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json;charset=UTF-8'
				},

			};
			console.log("actualizando datos a las " + new Date().toISOString())
			axios(options).then(response => {
				let sponsors = response.data.sort((a, b) => a.categoryOrder - b.categoryOrder || a.orderInCategory - b.orderInCategory)
					.filter(o => o.showInCarousel == true && o.deleted == 0)
				resolve(sponsors);
			})
		});


	},
	getExhibitorRepresentative: function (idExhibitor) {
		return new Promise(resolve => {
			axios.get(__API_EXHIBITOR_REPRESENTATIVE__ + idExhibitor + "/exhibitorInfo").then(resp => {

				resolve(resp.data);

			}).catch(error => {
				console.log("Error fetching package RoomStreaming: ")
				console.log(error)
			})
		});
	},
	getExhibitorProducts: function (idExhibitor) {
		return new Promise(resolve => {
			axios.get(__API_EXHIBITOR_REPRESENTATIVE__ + idExhibitor + "/exhibitorProducts").then(resp => {

				resolve(resp.data);

			}).catch(error => {
				console.log("Error fetching package RoomStreaming: ")
				console.log(error)
			})
		});
	},
	getAttendeesForBSRounds() {
		const options = {
			url: __BR_ATTENDEES_PAGINATED__ + "?currentPage=1&resultsPerPage=3000&searchString=&orderByFields=Person.Company&IsBusinessRound=true",
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json;charset=UTF-8',
				'registrationCode': Storage.getPersonRegistrationCode()
			},
		}

		return axios(options)
			.then((response) => {
				console.log()
				// let filterBy = Utils.getAttendeesFilterBy();
				// 	conversations = convers;
				// 	let resp = conversations.data.Items.filter(a => a.registrationType.toLowerCase() == filterBy);


				// 	let newConversations = resp.
				// 		filter(o => o.id != Storage.getIdPerson()).
				// 		map(result => {

				// 			return {
				// 				photo: Utils.getProfilePictureOrUnknown(result.pictureId),
				// 				name: `${result.firstName} ${result.lastName}`,
				// 				company: result.company,
				// 				about: result.about,
				// 				title: result.title,
				// 				website: result.webSite,
				// 				twitter: result.twitterUser,
				// 				idPerson: result.id,
				// 				id: result.id,
				// 				idRegistration: result.idRegistration,
				// 				country: result.country
				// 			};
				// 		});
			})
	},
	async getCalendar() {
		const options = {
			url: __BR_MY_MEETINGS_SLOTS__ + Storage.getIdRegistration(),
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json;charset=UTF-8'
			},

		};
		try {
			const { data } = await axios(options)
			const meetingDays = []
			const meetings = []
			const myCalendar = data.map(slot => {
				return {
					...slot,
					meetingStartDateTimeObj: moment.utc(slot.meetingStartDateTime),
					meetingEndDateTimeObj: moment.utc(slot.meetingEndDateTime),
				}
			})

			data.forEach(slot => {
				const date = slot.meetingStartDateTime.split("T")[0]
				if (!meetingDays.includes(date)) {
					meetingDays.push(date)
				}
			})

			meetingDays.forEach(day => {
				const dayMeetings = data.filter(slot => slot.meetingStartDateTime.split("T")[0] === day)
				meetings.push({
					date: day,
					meetings: dayMeetings
				})
			})

			return meetings
		} catch (error) {
			console.log("Error retrieving calendar information")
			console.log(error)
		}
	},
	async blockSlot(data) {
		const options = {
			url: __BR_BLOCK_SLOT__,
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json;charset=UTF-8',
				'registrationCode': Storage.getPersonRegistrationCode()
			},
			data
		};
		console.log(options);
		return axios(options).then(({ data }) => {
			return data
		}).catch(error => {
			console.error(error)
		})
	},
	async unblockSlot(data) {
		const options = {
			url: __BR_UNBLOCK_SLOT__,
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json;charset=UTF-8',
				'registrationCode': Storage.getPersonRegistrationCode()
			},
			data
		};
		axios(options).then(response => {
			console.log(response);
		}).catch(error => {
			console.error(error)
		})
	},
	async cancelMeeting(data) {
		const options = {
			url: __BR_RESPONSE_MEETING__,
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json;charset=UTF-8',
				"registrationCode": Storage.getPersonRegistrationCode()
			},
			data
		}
		return axios(options)
	}
}

export default ApiCalls
