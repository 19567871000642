import Services from '../../components/ApiCalls'
import { UPDATE_CALENDAR } from '../actionsTypes'

export const updateCalendar = () => {
    return async (dispatch) => {
        try {
            const payload = await Services.getCalendar()

            dispatch({
                type: UPDATE_CALENDAR,
                payload
            })
        } catch (error) {
            console.log(error);
        }
    }
}