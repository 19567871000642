import UnknownPic from '../assets/UnknownPic.png';
import onePxTransparent from '../assets/1pxTransparent.png';
import LogoKC from '../assets/logo-kingconf.png';
import { __IMAGEHANDLER_URL__, __SPEAKER_ALIAS__, __APIURLVIRTUALEXPO__, __EXHIBITOR_ALIAS__, __HOSTEDBUYER_ALIAS__, __ATTENDEES_ALIAS__, __VALID_MAILS_FOR_CERTIFICATES__, __APIKCURL__, __API_ATTENDEES_URL__, __BR_ROUNDS_GROUP_ONE__, __BR_ROUNDS_GROUP_TWO__, __EXHIBITOR_PROFILE__ } from '../consts/consts';
import Storage from './Storage';
import axios from 'axios';

let Utils = {
	guid: function () {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
			return v.toString(16);
		});
	},

	move: function (array, fromIndex, toIndex) {
		return array.splice(toIndex, 0, array.splice(fromIndex, 1)[0]);
	},


	isVideoCallLink: function (link) {
		if (link.match(/zoom.us/gi) ||
			link.match(/teams.microsoft/gi) ||
			link.match(/meet.google/gi)) {
			return true;
		}
		return false;
	},

	renderIf: function (condition, content) {
		if (condition) {
			return content;
		} else {
			return null;
		}
	},
	getDashboardWidth: function () {
		return document.getElementById("Dashboard-Wrapper").offsetWidth;
	},
	getDashboardHeight: function () {
		return document.getElementById("Dashboard-Wrapper").offsetHeight;
	},

	getProfilePictureOrUnknown: function (pictureId) {

		return pictureId == 0 || pictureId == null ? UnknownPic : __IMAGEHANDLER_URL__ + Math.abs(pictureId);
	},

	getPictureOrTransparent: function (pictureId) {
		return pictureId == 0 || pictureId == null ? onePxTransparent : __IMAGEHANDLER_URL__ + Math.abs(pictureId)
	},

	getEventLogoOrDefault: function (logoPictureId) {
		return logoPictureId == 0 || logoPictureId == null ? LogoKC : __IMAGEHANDLER_URL__ + Math.abs(logoPictureId)
	},

	getPlatformPicture: function (virtualEventPlatformPictureId) {
		return __IMAGEHANDLER_URL__ + Math.abs(virtualEventPlatformPictureId)
	},
	getUTCDate: function (inDate) {
		//Date in format 2020:10:00T00:00
		let outDate = new Date(inDate);
		outDate.setMinutes(outDate.getMinutes() + outDate.getTimezoneOffset());
		return outDate;
	},

	isValidForCertificate: function (email) {
		let validEmails = __VALID_MAILS_FOR_CERTIFICATES__;
		if (validEmails == "*" || validEmails.toLowerCase().indexOf(email.toLowerCase()) >= 0) {
			return true;
		}
		return false;
	},

	isEventFinished: function () {
		var moment = require("moment-timezone");
		const currentDate = moment.utc();
		if (Storage.getEventEndDate() == null) {
			return false;
		}

		if (currentDate.isAfter(moment(Storage.getEventEndDate()))) {
			return true;
		}

		return false;

	},

	getRandomNumber: function (topNumber) {
		return Math.round((Math.random() * 100000)) % topNumber;
	},

	logOut() {
		Storage.clearAll();
		setTimeout(function () {
			window.location.href = "/";
		}, 2000)
	},

	getLanguageOverride: function (locale) {
		switch (locale.split('-')[0]) { //Viene como es-ES
			case 'es': return Storage.getLanguageOverrideEs();
			case 'en': return Storage.getLanguageOverrideEn();
			case 'pt': return Storage.getLanguageOverridePt();
			case 'fr': return Storage.getLanguageOverrideFr();
		}
	},

	getStartupPage: function () {
		return Storage.getStartupPage();
	},

	replaceLinks: function (content) {
		if (content != null)
			return content.replaceAll('#https://', 'https://').replaceAll('#http://', 'http://');
		return content;
	},



	_getModuleConverted: function (module, itemNr, languageOverrideEs, languageOverrideEn, languageOverridePt, languageOverrideFr) {
		let iconSrc = null;
		let menuConverted = {};
		if (module.imgSrc.match('data:image') != null)
			iconSrc = module.imgSrc;

		switch (module.menuOption) {
			case 'home':
				menuConverted = {
					...module,
					id: 'home',
					icon: 'simple-icon-home',
					iconSrc: iconSrc,
					label: 'menu.home',
					to: '/',
				};
				languageOverrideEs['menu.home'] = module.menuButtonText_ES;
				languageOverrideEn['menu.home'] = module.menuButtonText_EN;
				languageOverridePt['menu.home'] = module.menuButtonText_PT;
				languageOverrideFr['menu.home'] = module.menuButtonText_FR;
				if (itemNr == 1) {
					Storage.setStartupPage(
						Storage.isHomeLobbyType() ? 'home' : 'panel'
					);
				}
				break;
			case 'liveStream':
				menuConverted = {
					...module,
					id: 'liveStream',
					icon: 'iconsminds-youtube',
					iconSrc: iconSrc,
					label: 'menu.liveStream',
					to: '/liveStream',
				};
				languageOverrideEs['menu.liveStream'] = module.menuButtonText_ES;
				languageOverrideEn['menu.liveStream'] = module.menuButtonText_EN;
				languageOverridePt['menu.liveStream'] = module.menuButtonText_PT;
				languageOverrideFr['menu.liveStream'] = module.menuButtonText_FR;
				if (itemNr == 1) {
					Storage.setStartupPage('liveStream');
				}
				break;
			case 'exhibition':
				menuConverted = {
					...module,
					id: 'fair',
					icon: 'iconsminds-opera-house',
					iconSrc: iconSrc,
					label: 'menu.fair',
					to: '/fair',
				};
				languageOverrideEs['menu.fair'] = module.menuButtonText_ES;
				languageOverrideEn['menu.fair'] = module.menuButtonText_EN;
				languageOverridePt['menu.fair'] = module.menuButtonText_PT;
				languageOverrideFr['menu.fair'] = module.menuButtonText_FR;
				if (itemNr == 1) {
					Storage.setStartupPage('fair');
				}
				break;
			case 'Sessions':
			case 'programmeByDay':
				menuConverted = {
					...module,
					id: 'programmeByDay',
					icon: 'simple-icon-calendar',
					iconSrc: iconSrc,
					label: 'menu.programme',
					to: '/programme/programmeView',
				};
				languageOverrideEs['menu.programme'] = module.menuButtonText_ES;
				languageOverrideEn['menu.programme'] = module.menuButtonText_EN;
				languageOverridePt['menu.programme'] = module.menuButtonText_PT;
				languageOverrideFr['menu.programme'] = module.menuButtonText_FR;
				if (itemNr == 1) {
					Storage.setStartupPage('programmeByDay');
				}
				break;
			case 'speakers':
				menuConverted = {
					...module,
					id: 'speakers',
					icon: 'simple-icon-microphone',
					iconSrc: iconSrc,
					label: 'menu.speakers',
					to: '/speakers/speakersDetail',

				};
				languageOverrideEs['menu.speakers'] = module.menuButtonText_ES;
				languageOverrideEn['menu.speakers'] = module.menuButtonText_EN;
				languageOverridePt['menu.speakers'] = module.menuButtonText_PT;
				languageOverrideFr['menu.speakers'] = module.menuButtonText_FR;
				if (itemNr == 1) {
					Storage.setStartupPage('speakers');
				}
				break;

			case 'programme':
				menuConverted = {
					...module,
					id: 'programme',
					icon: 'simple-icon-calendar',
					iconSrc: iconSrc,
					label: 'menu.programme',
					to: '/programme/programmeView',
					subs: [
						{
							icon: 'simple-icon-calendar',
							iconSrc: iconSrc,
							label: 'menu.programme',
							to: '/programme/programmeView',
						},
						{
							id: 'speakersDetail',
							iconSrc: iconSrc,
							icon: 'simple-icon-microphone',
							label: 'menu.speakers',
							to: '/speakers/speakersDetail',
						}
					],

				};
				languageOverrideEs['menu.programme'] = module.menuButtonText_ES;
				languageOverrideEn['menu.programme'] = module.menuButtonText_EN;
				languageOverridePt['menu.programme'] = module.menuButtonText_PT;
				languageOverrideFr['menu.programme'] = module.menuButtonText_FR;
				break;
			case 'businessRound':
				let subs = []

				subs.push(
					{
						icon: 'iconsminds-affiliate',
						iconSrc: iconSrc,
						label: 'menu.makemeeting',
						to: '/businessRound/businessRoundView',
					}
				)
				subs.push({
					id: 'myMeetingsView',
					iconSrc: iconSrc,
					icon: 'iconsminds-address-book-2',
					label: 'menu.mymeetings',
					to: '/businessRound/myMeetingsView',
				})
				menuConverted = {
					...module,
					id: 'businessRound',
					icon: 'iconsminds-speach-bubbles',
					iconSrc: iconSrc,
					label: 'menu.brounds',
					subs: subs,

				}
				languageOverrideEs['menu.brounds'] = module.menuButtonText_ES;
				languageOverrideEn['menu.brounds'] = module.menuButtonText_EN;
				languageOverridePt['menu.brounds'] = module.menuButtonText_PT;
				languageOverrideFr['menu.brounds'] = module.menuButtonText_FR;
				break;
			case 'attendees':
				menuConverted = {
					...module,
					id: 'attendees',
					icon: 'simple-icon-people',
					iconSrc: iconSrc,
					label: 'menu.attendees',
					to: '/panel',
				}
				languageOverrideEs['menu.attendees'] = module.menuButtonText_ES;
				languageOverrideEn['menu.attendees'] = module.menuButtonText_EN;
				languageOverridePt['menu.attendees'] = module.menuButtonText_PT;
				languageOverrideFr['menu.attendees'] = module.menuButtonText_FR;
				break;
			case 'posters':
				menuConverted = {
					...module,
					id: 'posters',
					icon: 'iconsminds-blackboard',
					iconSrc: iconSrc,
					label: 'menu.posters',
					to: '/posters',
				}
				languageOverrideEs['menu.posters'] = module.menuButtonText_ES;
				languageOverrideEn['menu.posters'] = module.menuButtonText_EN;
				languageOverridePt['menu.posters'] = module.menuButtonText_PT;
				languageOverrideFr['menu.posters'] = module.menuButtonText_FR;
				break;
			case 'sponsors':
				menuConverted = {
					...module,
					id: 'sponsorsData',
					icon: 'iconsminds-crown-2',
					iconSrc: iconSrc,
					label: 'menu.sponsors',
					to: '/sponsors/sponsorsData',
				}
				languageOverrideEs['menu.sponsors'] = module.menuButtonText_ES;
				languageOverrideEn['menu.sponsors'] = module.menuButtonText_EN;
				languageOverridePt['menu.sponsors'] = module.menuButtonText_PT;
				languageOverrideFr['menu.sponsors'] = module.menuButtonText_FR;
				break;
			case 'htmlLink':
				menuConverted = {
					...module,
					id: 'htmlLink_' + itemNr,
					icon: 'simple-icon-web',
					iconSrc: iconSrc,
					label: 'menu.htmlLink_' + itemNr,
					to: module.menuOptionConfiguration.url,

				}
				let menuEs = module.menuButtonText_ES;
				if (module.menuButtonText_ES == 'Mis compras' && Storage.getRegistrationType() == __EXHIBITOR_PROFILE__) {
					menuEs = "Mis ventas";
				}
				languageOverrideEs['menu.htmlLink_' + itemNr] = menuEs;
				languageOverrideEn['menu.htmlLink_' + itemNr] = module.menuButtonText_EN;
				languageOverridePt['menu.htmlLink_' + itemNr] = module.menuButtonText_PT;
				languageOverrideFr['menu.htmlLink_' + itemNr] = module.menuButtonText_FR;
				break;
			case 'restrictedPages':
				menuConverted = {
					...module,
					id: 'restricteredPages_' + itemNr,
					icon: 'simple-icon-lock',
					iconSrc: iconSrc,
					label: 'menu.restricteredPages_' + itemNr,
					to: '/restricteredPages',
				}
				languageOverrideEs['menu.restricteredPages_' + itemNr] = module.menuButtonText_ES;
				languageOverrideEn['menu.restricteredPages_' + itemNr] = module.menuButtonText_EN;
				languageOverridePt['menu.restricteredPages_' + itemNr] = module.menuButtonText_PT;
				languageOverrideFr['menu.restricteredPages_' + itemNr] = module.menuButtonText_FR;
				break;
			case 'htmlGenericView':
				menuConverted = {
					...module,
					id: 'htmlGenericView_' + itemNr,
					icon: 'iconsminds-information',
					iconSrc: iconSrc,
					label: 'menu.htmlGenericView_' + itemNr,
					to: '/pages/' + module.menuOptionConfiguration.pageCode,
				}
				languageOverrideEs['menu.htmlGenericView_' + itemNr] = module.menuButtonText_ES;
				languageOverrideEn['menu.htmlGenericView_' + itemNr] = module.menuButtonText_EN;
				languageOverridePt['menu.htmlGenericView_' + itemNr] = module.menuButtonText_PT;
				languageOverrideFr['menu.htmlGenericView_' + itemNr] = module.menuButtonText_FR;
				break;
			case 'htmlGenericViewList':
				menuConverted = {
					...module,
					id: 'htmlGenericViewList_' + itemNr,
					icon: 'iconsminds-information',
					iconSrc: iconSrc,
					label: 'menu.htmlGenericViewList_' + itemNr,
					to: '/pagesList/' + module.menuButtonText_ES.toLowerCase(),
				}
				languageOverrideEs['menu.htmlGenericViewList_' + itemNr] = module.menuButtonText_ES;
				languageOverrideEn['menu.htmlGenericViewList_' + itemNr] = module.menuButtonText_EN;
				languageOverridePt['menu.htmlGenericViewList_' + itemNr] = module.menuButtonText_PT;
				languageOverrideFr['menu.htmlGenericViewList_' + itemNr] = module.menuButtonText_FR;
				break;
			case 'ListMenu': //TODO: falta implementar
				menuConverted = {
					...module,
					id: 'ListMenu_' + itemNr,
					icon: 'simple-icon-menu',
					iconSrc: iconSrc,
					label: 'menu.listMenu_' + itemNr,
					to: '/',
					subs: [],
				}
				languageOverrideEs['menu.listMenu_' + itemNr] = module.menuButtonText_ES;
				languageOverrideEn['menu.listMenu_' + itemNr] = module.menuButtonText_EN;
				languageOverridePt['menu.listMenu_' + itemNr] = module.menuButtonText_PT;
				languageOverrideFr['menu.listMenu_' + itemNr] = module.menuButtonText_FR;
				break;
			case 'videos':
				menuConverted = {
					...module,
					id: 'videos',
					icon: 'simple-icon-control-play',
					iconSrc: iconSrc,
					label: 'menu.videos',
					to: '/videos',
				}
				languageOverrideEs['menu.videos'] = module.menuButtonText_ES;
				languageOverrideEn['menu.videos'] = module.menuButtonText_EN;
				languageOverridePt['menu.videos'] = module.menuButtonText_PT;
				languageOverrideFr['menu.videos'] = module.menuButtonText_FR;
				break;
			case 'support':
				menuConverted = {
					...module,
					id: 'support',
					icon: 'simple-icon-control-play',
					iconSrc: iconSrc,
					label: 'menu.support',
					to: '/pages/' + module.menuOptionConfiguration.pageCode,
				}
				languageOverrideEs['menu.support'] = module.menuButtonText_ES;
				languageOverrideEn['menu.support'] = module.menuButtonText_EN;
				languageOverridePt['menu.support'] = module.menuButtonText_PT;
				languageOverrideFr['menu.support'] = module.menuButtonText_FR;
				break;
			case 'internalLink':
				menuConverted = {
					...module,
					id: 'internalLink_' + itemNr,
					icon: 'simple-icon-web',
					iconSrc: iconSrc,
					label: 'menu.internalLink_' + itemNr,
					to: module.menuOptionConfiguration.url,
				}
				languageOverrideEs['menu.internalLink_' + itemNr] = module.menuButtonText_ES;
				languageOverrideEn['menu.internalLink_' + itemNr] = module.menuButtonText_EN;
				languageOverridePt['menu.internalLink_' + itemNr] = module.menuButtonText_PT;
				languageOverrideFr['menu.internalLink_' + itemNr] = module.menuButtonText_FR;
				break;
			case 'forums':
				let category = '';
				if (module.registeredOnly == "1") {
					category = "/restrictered"
				}
				else {
					if (module.menuButtonText_ES == "After") {
						category = "/public"
					}
				}
				menuConverted = {
					...module,
					id: 'forums_' + itemNr,
					icon: 'iconsminds-speach-bubble-comic-2',
					iconSrc: iconSrc,
					label: 'menu.forums_' + itemNr,
					to: '/forums' + category
				}
				languageOverrideEs['menu.forums_' + itemNr] = module.menuButtonText_ES;
				languageOverrideEn['menu.forums_' + itemNr] = module.menuButtonText_EN;
				languageOverridePt['menu.forums_' + itemNr] = module.menuButtonText_PT;
				languageOverrideFr['menu.forums_' + itemNr] = module.menuButtonText_FR;
				break;
		}
		return menuConverted;
	},
	convertMenu: function (menu) {
		let menuConverted = [];
		let languageOverrideEs = {};
		let languageOverrideEn = {};
		let languageOverridePt = {};
		let languageOverrideFr = {};
		let itemNr = 0;
		menu.forEach(module => {

			itemNr++;
			let moduleConverted = this._getModuleConverted(module, itemNr, languageOverrideEs, languageOverrideEn, languageOverridePt, languageOverrideFr);
			if (module.menuOption == "ListMenu") {
				module.menuOptionConfiguration.listMenuButtonStoreData.forEach(
					moduleSubMenu => {
						itemNr++;
						let moduleConverted2 = this._getModuleConverted(moduleSubMenu, itemNr, languageOverrideEs, languageOverrideEn, languageOverridePt, languageOverrideFr);
						if (moduleConverted2 != null)
							moduleConverted.subs.push(moduleConverted2);
					}
				)
			}
			menuConverted.push(moduleConverted);
			Storage.setLanguageOverrideEs(languageOverrideEs);
			Storage.setLanguageOverrideEn(languageOverrideEn);
			Storage.setLanguageOverridePt(languageOverridePt);
			Storage.setLanguageOverrideFr(languageOverrideFr);
		}
		);
		return menuConverted.sort((a, b) => b.order - a.order);
	},

	getDescriptionText(activity, locale) {
		switch (locale) {
			case "en":
				return activity.description_EN != "" ? activity.description_EN : activity.description;
			case "es":
				return activity.description_ES != "" ? activity.description_ES : activity.description;
			case "pt":
				return activity.description_PT != "" ? activity.description_PT : activity.description;
			case "fr":
				return activity.description_FR != "" ? activity.description_FR : activity.description;
			default:
				return activity.description;
		}

	},
	getTitleText(activity, locale) {
		switch (locale) {
			case "en":
				return activity.title_EN != "" ? activity.title_EN : activity.title;
			case "es":
				return activity.title_ES != "" ? activity.title_ES : activity.title;
			case "pt":
				return activity.title_PT != "" ? activity.title_PT : activity.title;
			case "fr":
				return activity.title_FR != "" ? activity.title_FR : activity.title;
			default:
				return activity.title;
		}

	},

	getAttendeesFilterBy() {

		let myRegistrationType = Storage.getRegistrationType().toLowerCase();
		if (myRegistrationType == __BR_ROUNDS_GROUP_ONE__ ||
			myRegistrationType == __BR_ROUNDS_GROUP_TWO__) {
			switch (myRegistrationType) {
				case __BR_ROUNDS_GROUP_ONE__: return __BR_ROUNDS_GROUP_TWO__;
				case __BR_ROUNDS_GROUP_TWO__: return __BR_ROUNDS_GROUP_ONE__;
				default: return '';
			}
		}
	},


	htmlDecode(t) {
		if (t != null)
			return t.replace(/&lt;/g, '<').replace(/&gt;/g, '>')
		return '';
	},

	logOnConsole(message) {
		console.log(new Date().toISOString() + ': ' + message);
	},

	logOnClick(type, idExhibitor, additionalData) {
		let formdata = new FormData();
		formdata.append("idExhibitor", idExhibitor);
		formdata.append("additionalData", additionalData);

		let myUrl = '';
		if (type == "video") {
			myUrl = 'logOnVideoClick';
		}
		if (type == "banner") {
			myUrl = 'logOnBannerClick';
		}
		if (type == "360Tour") {
			myUrl = 'logOn360TourClick';
		}

		const options = {
			url: __APIURLVIRTUALEXPO__ + 'logger/' + myUrl,
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json;charset=UTF-8',
				//La siguiente linea hace la magia de autenticar con el token!
				'Authorization': 'bearer ' + Storage.getJwtToken()
			},
			data: formdata
		};
		axios(options).then(response => {
			console.log(response);

		}).catch(error => {
			console.log(error);

		})
	},


	getFileHandlerUrl(idFile) {
		return "https://www.kingconf.com/Handlers/EventFileHandler.ashx?AcEventFileId=" + idFile + "&AcEventFileInlineContent=true";
	},

	createRegExWithAcentosInsensitive(patronABuscar, palabrasEnCualquierOrden) {
		if (palabrasEnCualquierOrden == undefined)
			palabrasEnCualquierOrden = true;
		//supuestamente tambien se puede escribir el caracter é en unicode así: e\u0341 pero no logro hacer andar esto combinado con [eéêe\u0341]]
		var arraySpecialCharacters = [[/[aáâã]/g, '[aáâã]'], [/[eéê]/g, '[eéê]'], [/[ií]/g, '[ií]'], [/[oóõô]/g, '[oóõô]'], [/[uúü]/g, '[uúü]'], [/[cç]/g, '[cç]']];
		palabrasEnCualquierOrden = false;
		arraySpecialCharacters.forEach(function (a) { patronABuscar = patronABuscar.replace(a[0], a[1]); });
		let regexPattern = patronABuscar;
		if (palabrasEnCualquierOrden) {
			regexPattern = '';
			var palabras = patronABuscar.split(' ');
			palabras.forEach(function (palabra) {
				if (palabra.trim() != '')
					regexPattern = regexPattern + '(?=.*' + palabra.trim() + ')';
			}
			);
		}
		else {
			//Esto es para que cuando se busca con palabras ordenadas acepte palabras intermedias (reemplaza espacios por comodines)
			//  patronABuscar = patronABuscar.replace(/\s/g, ".*");
			regexPattern = patronABuscar;
		}
		return new RegExp(regexPattern, "i");
	},

	getSpeakerName(values) {
		var speakerName = '';
		if (values.treatment != null && values.treatment != '') {
			speakerName = values.treatment + ' ';
		}
		if (values.firstName == null && values.lastName == null) {
			//ESTO LO PONGO POR SI SE HACE UN UPDATE A UNA APP VIEJA QUE NO TIENE LOS CAMPOS NUEVOS
			return speakerName += values.name;
		}
		if (Storage.getShowPeopleLastNameFirst() == false) {
			speakerName += values.firstName + ' ' + values.lastName;
		}
		else {
			var nameSeparator = '';
			if (values.firstName && values.lastName)
				nameSeparator = ', ';
			speakerName += values.lastName + nameSeparator + values.firstName;
		}
		return speakerName;
	},

	insertCss: function (code) {
		var style = document.createElement('style');
		style.type = 'text/css';

		if (style.styleSheet) {
			// IE
			style.styleSheet.cssText = code;
		} else {
			// Other browsers
			style.innerHTML = code;
		}

		document.getElementsByTagName("head")[0].appendChild(style);
	},
	getRegistrationTypeAlias(registrationType) {
		switch (registrationType) {
			case "Exhibitor": return __EXHIBITOR_ALIAS__;
			case "HostedBuyer": return __HOSTEDBUYER_ALIAS__;
			case "Attendee": return __ATTENDEES_ALIAS__;
			case "Speaker": return __SPEAKER_ALIAS__;
		}
	},
	removeHtml: function (text) {
		const regex = /(<([^>]+)>)/ig;
		return text.replace(regex, '');
	},
	logOnConsole(message) {
		console.log(new Date().toISOString() + ': ' + message);
	},
	sendErrorToServer: function (message, module, errorThrown) {

		let deviceId = "";
		deviceId += Math.trunc(Math.random() * 10000) + navigator.userAgent;
		let messageToreport = message + " in module " + module + " error thrown: " + errorThrown;
		const options = {
			url: __APIKCURL__ + 'Attendees/',
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: {
				networkingAction: 'SendErrorReport',
				idEvent: Number(Storage.getIdEvent()),
				feedback: encodeURIComponent('<h2> Este usuario experimento un ERROR: ' + Storage.getPersonCompletename() + '</h2><h2> RegistrationID: ' + Storage.getIdRegistration() +
					'</h2><h2>Email: ' + Storage.getPersonEmail() + '<br><br><h2>' +
					'</h2><h2>EventId: ' + Storage.getIdEvent() + '<br><br><h2>' +
					'</h2><h2>Token: ' + Storage.getJwtToken() + '<br><br><h2>' +
					'ErrorInfo:</h2>' + messageToreport + '<br> + |END|'),
				deviceId: deviceId
			}
		};

		axios(options).then(resp => {
			console.log('An Error has occurred sended to server OK--------')
		}).catch(error => {

			console.error('reporte de errores enviado al servidor! ' + messageToreport);
		});//fin del Ajax call
	},

	isMobile: function () {
		return (window.outerWidth <= 464);
	},
	checkIfEqualsArrays(array1, array2) {
		//Verifica que los objetos de array1 y 2 son iguales
		if (array1.length != array2.length)
			return false;
		if (array1 == null && array2 == null)
			return true
		for (let i = 0; i < array1.length; i++) {
			if (!this.checkIfEqualsObjects(array1[i], array2[i]))
				return false;
		}
		return true;

	},
	checkIfEqualsObjects(obj1, obj2) {
		return JSON.stringify(obj1) === JSON.stringify(obj2);
	},
	isNullOrEmpty(str) {
		return (str == null || str == '')
	},
	getInitialURLParams() {
		const params = {}
		window.location.search.split("&").forEach((param, index) => {
			if (index === 0) {
				param = param.slice(1)
			}
			if (param) {
				params[param.split("=")[0]] = param.split("=")[1]
			}
		})
		return params
	},
	credentialsMatch() {
		const storedEmail = Storage.getPersonEmail()
		const urlParams = JSON.parse(window.localStorage.getItem('urlParams'))
		try {
			const receivedAccessCode = atob(urlParams.accesscode)
			return storedEmail === receivedAccessCode || !receivedAccessCode

		} catch (error) {
			return true
		}
	},
	eventMatch() {
		const storedEvent = parseInt(window.localStorage.getItem('AcEventId'))
		const urlParams = JSON.parse(window.localStorage.getItem('urlParams'))
		const receivedEvent = parseInt(urlParams.AcEventId)

		return storedEvent === receivedEvent || !receivedEvent
	}
};

export default Utils;
