import { UPDATE_CALENDAR } from '../actionsTypes'

const initialState = {
    calendar: []
}

const businessRoundsReducer = (state = initialState, action) => {

    switch (action.type) {
        case UPDATE_CALENDAR:
            return {
                calendar: action.payload
            }

        default:
            return state;
    }
}

export default businessRoundsReducer